<template>
  <div class="grid p-fluid mt-3">
    <div class="center-container panel">
      <div class="card fixed-login">
        <Toast/>
        <div class="">
          <h5>SIGN IN</h5>
        </div>
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
                <span class="p-input-icon-left ">
                  <i class="pi pi-user"/>
                  <InputText ref="username" v-model="username" type="text" placeholder="Username"
                             @keyup.enter="signin()"/>
                </span>
          </div>
          <div class="col-12 md:col-12">
                <span class="p-input-icon-left">
                  <i class="pi pi-key"/>
                  <InputText v-model="password" type="password" placeholder="Password" @keyup.enter="signin()"/>
                </span>
          </div>
          <div class="col-12 md:col-12">
            <Button label="Sign in" class="p-button-outlined mr-2 mb-2" @click="signin()"/>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import UserService from "@/service/UserService";
import router from "../router";

export default {
  name: "Login",
  created() {
    this.userService = new UserService();
  },
  mounted() {
    this.$nextTick(() => this.$refs.username.$el.focus());
  },
  data() {
    return {
      username: "",
      password: "",
      loading: [false]
    }
  },
  methods: {
    async signin() {
      this.loading[0] = true;
      setTimeout(() => this.loading[0] = false, 1000);
      try {
        await this.userService.signin(this.username, this.password);
        router.push('/');
      } catch (error) {
        this.password = "";
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'No such credential found', life: 3000});
      }
    },
  }
}
</script>

<style scoped>
.center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
