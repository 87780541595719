import {creds} from '@/states/Cred';
import axios from 'axios';

export default class UserService {
    getUser() {
        return creds.user;
    }

    async createUser(data) {
        const response = await fetch(`/user/user`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({user: data})
        })
        return await response.json();
    }

    async getAllUsers() {
        const response = await fetch('/user/users');
        return await response.json();
    }

    async signin(user, pass) {
        const credentials = `grant_type=password&username=${user}&password=${pass}`
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth`, credentials);
        creds.token = response.data.accessToken;
        return response.data;
    }
}
